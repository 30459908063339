const forms = [
  {
    section: 'Data Diri Pendaftar',
    id: 'biodata',
    forms: [
      {
        name: 'register_name',
        label: 'Nama Pendaftar',
        type: 'text',
        placeholder: 'Masukan nama pendaftar',
        value_key: 'register_name',
        errorMessage: 'Nama Pendaftar harus diisi',
        required: true,
      },
      {
        name: 'phone_number',
        label: 'No. Handphone',
        type: 'number',
        prefix: '+62',
        placeholder: 'diawali dengan angka 8',
        value_key: 'phone_number',
        errorMessage: 'No. Handphone harus diisi',
        required: true,
      },
      {
        name: 'phone_number_two',
        label: 'No. Handphone Alternatif',
        type: 'number',
        prefix: '+62',
        placeholder: 'diawali dengan angka 8',
        value_key: 'phone_number_two',
        required: false,
      },
      {
        name: 'register_email',
        label: 'Email',
        type: 'text',
        placeholder: 'Masukan email pendaftar',
        value_key: 'register_email',
        errorMessage: 'Email harus diisi',
        required: true,
      },
      {
        name: 'resource',
        label: 'Dari mana Anda Mendapatkan Informasi Tentang Pendaftaran Ini?',
        type: 'dropdown',
        options: [
          {
            label: 'Rekomendasi dari Konsolidator',
            value: 'Rekomendasi dari Konsolidator',
          },
          {
            label: 'Rekomendasi dari Agen POS lain',
            value: 'Rekomendasi dari Agen POS lain',
          },
          {
            label: 'Social Media',
            value: 'Social Media',
          },
          {
            label: 'Website Lion Parcel',
            value: 'Website Lion Parcel',
          },
        ],
        placeholder: 'Pilih',
        value_key: 'resource',
        errorMessage: 'Darimana Anda Mendapatkan Informasi Tentang Pendaftaran Ini? harus diisi',
        required: true,
      },
    ],
  },
  {
    section: 'Data Lokasi Yang Diusulkan',
    id: 'location_data',
    forms: [
      {
        name: 'building_location',
        label: 'Lokasi',
        type: 'dropdown',
        options: [
          {
            label: 'Mall / Pusat Perbelanjaan',
            value: 'Mall / Pusat Perbelanjaan',
          },
          {
            label: 'Perkantoran',
            value: 'Perkantoran',
          },
          {
            label: 'Perumahan',
            value: 'Perumahan',
          },
          {
            label: 'Jalan Utama',
            value: 'Jalan Utama',
          },
        ],
        placeholder: 'Pilih lokasi',
        value_key: 'building_location',
        errorMessage: 'Lokasi harus diisi',
        required: true,
      },
      {
        name: 'building_type',
        label: 'Jenis Bangunan',
        type: 'dropdown',
        options: [
          {
            label: 'Ruko',
            value: 'Ruko',
          },
          {
            label: 'Kios',
            value: 'Kios',
          },
          {
            label: 'Rumah',
            value: 'Rumah',
          },
        ],
        placeholder: 'Pilih jenis bangunan',
        value_key: 'building_type',
        errorMessage: 'Jenis Bangunan harus diisi',
        required: true,
      },
      {
        name: 'building_status',
        label: 'Status Bangunan',
        type: 'dropdown',
        options: [
          {
            label: 'Milik Sendiri',
            value: 'Milik Sendiri',
          },
          {
            label: 'Sewa',
            value: 'Sewa',
          },
        ],
        placeholder: 'Pilih status bangunan',
        value_key: 'building_status',
        errorMessage: 'Status Bangunan harus diisi',
        required: true,
      },
      {
        name: 'is_have_other_business',
        label: 'Apakah Anda Mempunyai Usaha Lain?',
        type: 'dropdown',
        options: [
          {label: 'Online Shop Pakaian', value: 'Online Shop Pakaian'},
          {label: 'Online Shop FnB', value: 'Online Shop FnB'},
          {label: 'Perdagangan Umum', value: 'Perdagangan Umum'},
          {label: 'Penyedia Jasa lainnya', value: 'Penyedia Jasa lainnya'},
          {label: 'Ekspedisi lainnya', value: 'Ekspedisi lainnya'},
          {label: 'Lainnya', value: 'Lainnya'},
          {label: 'Tidak ada', value: 'Tidak ada'}
        ],
        placeholder: 'Pilih',
        value_key: 'is_have_other_business',
        errorMessage: 'Apakah Anda Mempunyai Usaha Lain harus diisi',
        required: true,
      },
      {
        name: 'address_location',
        label: 'Alamat Lokasi',
        type: 'text',
        placeholder: 'Masukan alamat lokasi',
        value_key: 'address_location',
        errorMessage: 'Alamat Lokasi harus diisi',
        required: true,
      },
      {
        name: 'province',
        label: 'Provinsi',
        type: 'dropdown',
        options: [],
        placeholder: 'Pilih provinsi',
        value_key: 'province',
        errorMessage: 'Provinsi harus diisi',
        required: true,
        isSearch: true,
      },
      {
        name: 'district',
        label: 'Kota',
        type: 'dropdown',
        options: [],
        placeholder: 'Pilih kota',
        value_key: 'district',
        errorMessage: 'Kota harus diisi',
        required: true,
        isSearch: true,
      },
      {
        name: 'sub_district',
        label: 'Kecamatan',
        type: 'dropdown',
        options: [],
        placeholder: 'Pilih kecamatan',
        value_key: 'sub_district',
        errorMessage: 'Kecamatan harus diisi',
        required: true,
        isSearch: true,
      },
      {
        name: 'village',
        label: 'Kelurahan',
        type: 'dropdown',
        options: [],
        placeholder: 'Pilih kelurahan',
        value_key: 'village',
        errorMessage: 'Kelurahan harus diisi',
        required: true,
        isSearch: true,
      },
      {
        name: 'latlong',
        label: 'Titik Kordinat (Google Maps)',
        optional: true,
        type: 'text',
        placeholder: 'Contoh : -6.523523523523, 106.323323323323',
        placeholder_mobile: 'Contoh : -6.523523523523',
        value_key: 'latlong',
        iconType: 'map',
        required: false,
      },
      {
        name: 'referral_code',
        label: 'Kode Referral',
        optional: true,
        type: 'text',
        placeholder: 'Masukkan kode referral',
        value_key: 'referral_code',
        required: false,
      },
      {
        name: 'captcha',
        label: 'Captcha',
        type: 'captcha',
        placeholder: '',
        component: 'Captcha',
        value_key: 'captcha',
        note: `
        </br>
        Dengan menyelesaikan pendaftaran maka saya setuju untuk mengikuti syarat dan ketentuan yang berlaku sesuai dengan <a href="https://storage.googleapis.com/internal-website/agent/Syarat%20&%20Ketentuan%20Agen%20Lion%20Parcel.pdf" target="_blank">Dokumen ini</a>`,
        required: true,
      },
    ],
  },
];

export default forms;
